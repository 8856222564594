import { html } from "lit";
import { when } from 'lit/directives/when.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';

export const LegalDisclaimer = (products: Array<any>, showLogo: boolean) => when(products?.filter(i => i.quote !== undefined).length > 0, () => html`
  <div class="legal-disclaimer-wrapper">
    ${when(showLogo, 
      () => html`<div class="vi-logo"><img src="https://images.squarespace-cdn.com/content/v1/62fa76612310b76e8cd82853/707a1c18-090f-4433-bada-3b3aea35afbf/VI-blue.png?format=100w" alt="Vertical Insure, Inc. Logo"/></div>`,
      () => ''
    )}
    <div class="legal-disclaimer">
      ${products?.filter(i => i.hidden === false)?.map((product) => html`
        <div>
          ${when(products.length > 1, () => html`
            <span class="product-name">${product.productDetails.friendly_name}: </span>
          `)}
          ${unsafeHTML(product.productDetails.legal_disclaimer)}
        </div>
      `)}
    </div>
  </div>
`, () => html``);
