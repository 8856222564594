import {css} from 'lit';

export const minimalProductCss = css`
  .simple-product-wrapper {
    padding-right: 5px;
    display: flex;
    flex: 1;
    align-items: stretch;
    cursor: pointer;
  }

  .simple-product-wrapper:last-child {
    padding-right: 0;
  }

  .product {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: var(--vi-color-bg, var(--vi-default-color-bg));
    border: 1px solid var(--vi-color-border, var(--vi-default-color-border));
    border-radius: var(--vi-border-radius, var(--vi-default-border-radius));
  }

  .product .icon {
    padding: 1rem 1rem 0;
  }
  .product > div {
    padding: 0 0.75rem;
  }
  .product > div:last-child {
    padding: 0.5rem 1rem;
  }

  .selected .product {
    background: var(--vi-color-success-bg, var(--vi-default-color-success-bg));
    border: 1px solid var(--vi-color-success-border, var(--vi-default-color-success-border));
    color: var(--vi-color-success-font, var(--vi-default-color-success-font));
  }

  .selected .product .title {
    background: var(--vi-color-success-bg, var(--vi-default-color-success-bg));
    color: var(--vi-color-success-font, var(--vi-default-color-success-font));
  }

  .selected .product .price {
    border-top: 1px solid var(--vi-color-success-border, var(--vi-default-color-success-border));
    color: var(--vi-color-success-font, var(--vi-default-color-success-font));
  }

  .selected .product .icon  {
    background: var(--vi-color-success-bg, var(--vi-default-color-success-bg));
    color: var(--vi-color-success-font, var(--vi-default-color-success-font));
  }

  .title {
    font-weight: 900;
    font-family: var(--vi-default-title-font);
    text-align: center;
    font-size: 1.2rem;
    margin: 0.65em 0px 0.65rem;
  }

  .description {
    flex: 1;
    font-size: 0.95rem;
    text-align: center;
    margin-bottom: 1em;
  }

  .description p { 
    margin: 0;
    display: inline;
  }

  .coverage-exceeded {
    flex: 1;
    font-size: 0.95rem;
    text-align: center;
  }

  .price {
    border-top: 1px solid var(--vi-color-border, var(--vi-default-color-border));
    color: var(--vi-color-primary, var(--vi-default-color-primary));
    text-align: center;
    font-weight: bold;
    font-size: 1.1rem;
  }

  .icon { 
    color: var(--vi-color-primary, var(--vi-default-color-primary));
    display: flex;
    justify-content: center;
  }

  .material-icons.md-18 { font-size: 18px; }
  .material-icons.md-24 { font-size: 24px; }
  .material-icons.md-36 { font-size: 36px; }
  .material-icons.md-48 { font-size: 48px; }

  .vi-simple-product-decline {
    margin-top: 5px;
    background: var(--vi-color-bg, var(--vi-default-color-bg));
    border-radius: var(--vi-border-radius, var(--vi-default-border-radius));
  }

  .vi-simple-product-decline label {
    border: 1px solid #e0e0e0;
    border-radius: var(--vi-border-radius, var(--vi-default-border-radius));
  }

  .radio-button-wrapper {
    position: relative;
    align-items: center;
    font-size: 0.85em;
    display: flex;
  }

  .radio-button-wrapper label {
    cursor: pointer;
    position: relative;
    display: block;
    width: 100%;
    border-bottom-left-radius: var(--vi-border-radius, var(--vi-default-border-radius));
    border-bottom-right-radius: var(--vi-border-radius, var(--vi-default-border-radius));
    padding: 0.75rem 0.75rem 0.75rem calc(1.5em + 13px);
  }

  .radio-button-wrapper input {
    z-index: 999;
    position: absolute;
    margin: 0;
    margin-left: 0.75rem;
  }

  .vi-simple-product-decline input:checked + label {
    background: var(--vi-color-danger-bg, var(--vi-default-color-danger-bg));
    border-color: var(--vi-color-danger-border, var(--vi-default-color-danger-border));
    color: var(--vi-color-danger-font, var(--vi-default-color-danger-font));
  }
`;