import { html } from "lit";
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import { formatDollar } from "../util.js";
import { FunctionalComponent } from "model/functional-component.js";

export const TabProduct: FunctionalComponent = ({selected, onSelect, onDecline, productDetails, quote, protectionAmount }) => html`
  <div class="tabs">
    <div class="tab-navigation">
      <div class="tab-navigation-item ${selected ? 'selected' : ''}" @click=${onSelect}>
        <input type="radio" name="tabs" id="accept-coverage" ?checked=${selected}>
        <label for="accept-coverage">
          Yes, protect my items for only <strong> ${formatDollar(quote?.premium_amount)}</strong>
        </label>
      </div>
      <div class="tab-navigation-item ${!selected ? 'selected' : ''}" @click=${onDecline}>
        <input type="radio" name="tabs" id="decline-coverage" ?checked=${!selected}>
        <label for="decline-coverage">
          No, do not protect my items of ${formatDollar(protectionAmount)}
        </label>
      </div>
    </div>
    <div class="tab-content">
      <div class="tab ${selected ? 'selected' : ''}">
        ${unsafeHTML(productDetails.promotional_description)}
      </div>
      <div class="tab ${!selected ? 'selected' : ''}">
        <ul>
          ${productDetails.perils?.map((peril: string) => html`<li>${peril}</li>`)}
        </ul>
      </div>
    </div>
  </div>
`;