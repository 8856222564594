import {css} from 'lit';

export const toggleProductCss = css`
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 5px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 5px;
}

input:checked + .slider {
  background-color: var(--vi-color-success, var(--vi-default-color-success));
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--vi-color-success, var(--vi-default-color-success));
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.toggle-action {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.checkbox-wrapper-4 * {
  box-sizing: border-box;
}
.checkbox-wrapper-4 .cbx {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.2s ease;
  display: inline-block;
  font-size: 1rem;
}
.checkbox-wrapper-4 .cbx:not(:last-child) {
  margin-right: 6px;
}
.checkbox-wrapper-4 .cbx:hover {
  background: rgba(0, 0, 0, 0.03);
}
.checkbox-wrapper-4 .cbx span {
  float: left;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-4 .cbx span:first-child {
  position: relative;
  width: 19px;
  height: 19px;
  border-radius: 4px;
  transform: scale(1);
  border: 1px solid var(--vi-color-primary, var(--vi-default-color-primary));
  transition: all 0.2s ease;
  box-shadow: 2px 2px 1px rgba(0,16,75,0.05);
}
.checkbox-wrapper-4 .cbx span:first-child svg {
  width: 14px;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 2px;
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-4 .cbx span:last-child {
  padding-left: 10px;
  line-height: 20px;
  font-size: 1.1rem;
}
.checkbox-wrapper-4 .cbx:hover span:first-child {
  border-color: var(--vi-color-success, var(--vi-default-color-success));
}
.checkbox-wrapper-4 .inp-cbx {
  position: absolute;
  visibility: hidden;
}
.checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child {
  background: var(--vi-color-success, var(--vi-default-color-success));
  border-color: var(--vi-color-success, var(--vi-default-color-success));
  animation: wave-4 0.4s ease;
}
.checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.checkbox-wrapper-4 .inline-svg {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}
@media screen and (max-width: 640px) {
  .checkbox-wrapper-4 .cbx {
    width: 100%;
    display: flex;
  }
}
@-moz-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@-webkit-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@-o-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}
@keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}



`;