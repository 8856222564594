import {css} from 'lit';

export const baseCss = css`
  :host {
    --vi-default-color-primary: #00263c;
    --vi-default-color-primary-contrast-text: #ffffff;
    --vi-default-color-primary-light: #5084A4;
    --vi-default-color-bg: #ffffff;
    --vi-default-color-secondary: #ff6b00;
    --vi-default-color-success: #75d48f;
    --vi-default-color-success-bg: #e9f8ed;
    --vi-default-color-success-border: #75d48f;
    --vi-default-color-success-font: #000000;
    --vi-default-color-danger: #e95b62;
    --vi-default-color-danger-bg: #fde8e9;
    --vi-default-color-danger-border: #f7a2a6;
    --vi-default-color-danger-font: #000000;
    --vi-default-color-border: #e0e0e0;
    --vi-default-border-radius: 5px;
    --vi-default-title-font: var(--vi-title-font, 'Playfair Display');
    --vi-default-font: var(--vi-font-family, sans-serif);
    font-family: var(--vi-default-font);
    --mdc-theme-primary: var(--vi-color-primary, var(--vi-default-color-primary));
    --mdc-typography-button-letter-spacing: 0;
    --mdc-typography-button-font-family: var(--vi-default-font);
  }

  input[type="radio"], input[type="checkbox"] {
    accent-color: var(--vi-color-primary, var(--vi-default-color-primary));
}

  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: 'liga';
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }

  .vi-component-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .vi-product-choice-view {
    position: relative;
    display: flex;
  }

  .min-block-size {
    min-height: 60px;
  }

  .vi-error {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--vi-color-border, var(--vi-default-color-border));
    border-radius: var(--vi-border-radius, var(--vi-default-border-radius));
    background-color: var(--vi-color-bg, var(--vi-default-color-bg));
  }

  .vi-error p {
    font-style: italic;
    
  }

  .vi-quote-wrapper {
    position: relative;
  }

  .legal-disclaimer-wrapper {
    display: flex;
    align-items: center;
  }

  .legal-disclaimer-wrapper .vi-logo {
    display: flex;
  }

  .legal-disclaimer-wrapper .vi-logo img {
    width: 75px;
  }

  .legal-disclaimer-wrapper .legal-disclaimer div {
    padding: 0 0.2rem;
    margin: 0.5rem 0;
  }

  .legal-disclaimer-wrapper .legal-disclaimer span.product-name {
    font-size: smaller;
    font-style: italic;
    font-weight: bold;
  }

  .legal-disclaimer-wrapper .legal-disclaimer p {
    font-size: smaller;
    font-style: italic;
    display: inline;
  }

  .missing-configuration {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  .auto-enable-input {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0px 14px;
  }

  .auto-enable-input label {
    font-size: 0.90rem;
  }

  .auto-enable-input input[type=checkbox] {
    transform: scale(1.2);
    margin: -1px 13px 0px 3px;
    accent-color: var(--vi-color-primary, var(--vi-default-color-primary));
  }

  .auto-enable-input input[type=checkbox], label {
    cursor: pointer;
  }

  .tracking-info {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(1, 1, 1, 0.1);
    border-radius: var(--vi-border-radius, var(--vi-default-border-radius));
    margin-bottom: 10px;
    height: 40px;
  }

  .tracking-info .tracking-number {
    font-family: "PT Mono", Helvetica, sans-serif;
    font-size: 0.9rem;
    margin-right: 10px;
  }

  .tracking-info .courier-icon {
    height: 35px;
    position: relative;
  }

  .tracking-info .courier-icon.error .tooltip {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    background: var(--vi-color-danger, var(--vi-default-color-danger));
    width: max-content;
    padding: 8px;
    top: -30px;
    color: white;
    border-radius: var(--vi-border-radius, var(--vi-default-border-radius));
    cursor: pointer;
  }

  .tracking-info .courier-icon.error:hover .tooltip {
    visibility: visible;
  }

  .tracking-info .courier-icon.error {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--vi-default-color-danger);
    cursor: pointer;
  }

  .tracking-info .courier-icon svg {
    height: 35px;
  }

  .invalid-message {
    padding: 0.5rem;
    border: 1px solid var(--vi-color-danger-border, var(--vi-default-color-danger-border));
    background: var(--vi-color-danger-bg, var(--vi-default-color-danger-bg));
    border-radius: var(--vi-border-radius, var(--vi-default-border-radius));
    color: var(--vi-color-danger-font, var(--vi-default-color-danger-font));
    margin-bottom: 10px;
  }

  a {
    color: var(--vi-color-primary, var(--vi-default-color-primary));
  }

  a:hover {
    text-decoration: none;
  }
`;