import { css } from "lit";

export const tabProductCss = css`
  .tabs {
    width: 100%;
    margin: 5px;
  }

  .tab-navigation {
    display: flex;
    margin-bottom: -1px;
  }

  .tabs .tab-navigation .tab-navigation-item {
    align-items: center;
    font-size: 0.9em;
    display: flex;
    background-color: #fff;
    transition: background ease 0.3s;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 3px;
  }

  .tab-navigation .tab-navigation-item label {
    cursor: pointer; 
    padding: 0.85rem 0.85rem 0.85rem calc(1.5em + 17px);
  }

  .tabs.selected .tab-navigation .tab-navigation-item {
    border-bottom-left-radius: var(--vi-border-radius, var(--vi-default-border-radius));
    border-bottom-right-radius: var(--vi-border-radius, var(--vi-default-border-radius));
  }

  .tabs .tab-navigation .tab-navigation-item.selected {
    border-bottom: 1px solid #f5f5f5;
    background: #f5f5f5;
  }

  .tab-navigation .tab-navigation-item input {
    transform: scale(1.3);
    z-index: 999;
    position: absolute;
    margin: 0;
    margin-left: 0.85rem;
  }

  .tabs .tab-content {
    min-height: 200px;
    background: #f5f5f5;
    border: 1px solid #e0e0e0;
    font-size: 0.95rem;
    border-radius: 5px;
    margin-top: -1px;
    padding: 20px;
    padding: 1rem;
    border-top-left-radius: 0;
  }

  .tabs .tab-content .tab {
    height: 100%;
    display: none;
  }

  .tabs .tab-content .tab.selected {
    display: block;
    flex: 1;
  }

  .tab-content ul {
    margin: 0;
    list-style: none;
    list-style-position: outside;
    column-width: min-content;
    padding-inline-start: 0;
    display: flex;
    flex-flow: column wrap;
    max-height: 200px;
    width: fit-content;
  }

  .tab-content ul li {
    padding-left: 0;
    display: flex;
    flex-direction: row;
    padding: 0.2rem;
    padding-right: 20px;
  }
  .tab-content ul li:before {
    display: inline-block;
    min-width: 25px;
    content: "\\2718";
    color: var(--vi-color-danger, var(--vi-default-color-danger));
  }

  .tab-content .row {
    padding: 0.5rem;
    display: flex;
  }

  mwc-select {
    background-color: #fff;
    flex: 1;
    margin-left: 5px;
  }

  mwc-textfield {
    background-color: #fff;
    flex: 1;
  }

  @media only screen and (max-width: 768px) {
    .tabs .tab-content {
      border-top-right-radius: 0;
    }

    .tabs .tab-navigation .tab-navigation-item {
      flex: 1;
    }

    .tabs .tab-navigation .tab-navigation-item:last-child {
      margin-right: 0;
    }
  }
`;