import { LitElement, PropertyValueMap } from 'lit';
import {customElement, property, state} from 'lit/decorators.js';
import { getTrackingInfo } from './lib/tracking-number';
import { api } from "./api";
import { productCoverageMaximum } from './util';

enum ProductType {
  EventParticipationCancellation = 'event-participation-cancelation-insurance',
  EventHost = 'event-host-insurance',
  Pet = 'pet-insurance',
  AccidentMedical = 'accident-medical',
  Shipping = "shipping"
}

/**
 * @tag vi-product
 * @tagname vi-product
 */
@customElement("vi-product")
class ViProduct extends LitElement {
  /**
   * The name of the product to quote. See <a href="https://docs.verticalinsure.com/docs/api-reference/get-products">List All Products</a> for a list of all products available.
   * @type {ProductType}
   */
  @property({attribute: true}) product: ProductType;

  /**
   * The effective start date of the policy, in UTC. Can be a UTC string or timestamp.
   * @type {Date}
   */
  @property({attribute: "policy-effective-date"}) policyEffectiveDate: Date;


  /**
   * The effective start date of the policy, in UTC. Can be a UTC string or timestamp.
   * @type {Date}
   */
  @property({attribute: "policy-expiration-date"}) policyExpirationDate: Date;

  @property({attribute: "quote-id"}) quoteId: string;

  /**
   * Attributes required for retrieving a quote for the product. 
   * See <a href="https://docs.verticalinsure.com/docs/api-reference/get-product-details">Get Product Details</a> for retrieving required quote attributes for various products.
   * @type Object
   */
  @property({attribute: "policy-attributes", type: Object, reflect: true}) policyAttributes: any;

  @property({attribute: "suffix-content", type: String}) suffixContent: string;

  /**
   * @private
   */
  @property({attribute: true, type: Object}) customer: any;

  /**
   * @private
   */
  @property() clientId: string;

  /**
   * @private
   */
  @property() order: number;

  /**
   * @private
   */
  @property() errors: Array<string> = [];

  /**
   * @private
   */
  @property() hidden: boolean = false;

  /**
   * @private
   */
  @state() productDetails: any;

  /**
   * @private
   */
  @state() quote: any;

  /**
   * @private
   */
  @state() selected: boolean;

  /**
   * @private
   */
  @state() loading: boolean = true;

  /**
   * @private
   */
  declare registered: boolean;

  /**
   * @private
   */
  declare displayComponent: Function;

  constructor() {
    super();
  }

  override connectedCallback(): void {
    super.connectedCallback();

    if (this.product === ProductType.Shipping && this.policyAttributes.courier_id === undefined) {
      this.policyAttributes = {...this.policyAttributes, courier_id: 1}
    }
  }

  protected override updated(changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    
    if (changedProperties.has("policyAttributes")) {
      if (this.product === ProductType.Shipping) {
        if (changedProperties.get("policyAttributes") !== undefined && this.policyAttributes?.tracking_number !== changedProperties.get("policyAttributes")?.tracking_number) {
          let courierCodes = {
            'usps': 1,
            'fedex': 2,
            'ups': 3
          }

          let defaultCourier = 1;
          let predefinedCourier = this.policyAttributes.courier_id;

          if (this.policyAttributes.tracking_number !== undefined) {
            const trackingData = getTrackingInfo(this.policyAttributes.tracking_number);

            if (!trackingData) {
              if (predefinedCourier === undefined) {
                this.policyAttributes = {...this.policyAttributes, courier_id: defaultCourier}
              }
            } else {
              const courierCode = courierCodes[trackingData.courier.courier_code];
              if (courierCode !== predefinedCourier) {
                this.policyAttributes = {...this.policyAttributes, courier_id: courierCode}
                return;
              }
            }
          }

          if (predefinedCourier === undefined) {
            this.policyAttributes = {...this.policyAttributes, courier_id: defaultCourier}
            return;
          } 
        }
      }
    }

    if (Array.from(changedProperties.keys()).some(i => [
      "clientId", 
      "customer", 
      "policyAttributes", 
      "policyExpirationDate", 
      "policyEffectiveDate",
      "order"
    ].includes(i.toString()))) {
      if (this.clientId !== undefined && !this.order !== undefined) {
        let protectionAmount = this.policyAttributes.insurable_amount || this.policyAttributes.declared_value;
        let maxProtectionAmount = productCoverageMaximum(this.product);

        if (protectionAmount <= maxProtectionAmount) {
          this.load().then(() => {
            this.registerSelf();
          });
        } else { 
          this.loadProductDetails().then(() => {
            this.registerSelf();
            this.loading = false;
          });
        }
      }
    } 

    if (changedProperties.has("loading") && changedProperties.get("loading") !== undefined) {
      this.dispatchEvent(new CustomEvent("loadingUpdate", {detail: this, bubbles: true}));
    }
  }

  private load() {
    this.loading = true;

    let metadata = {}

    if (this.parentElement?.dataset?.uniqueId) {
      metadata = {
        partner_item_unique_id: this.parentElement?.dataset?.uniqueId
      }
    }

    return api.createQuote(
      this.clientId, 
      this.product, 
      this.policyEffectiveDate, 
      this.policyExpirationDate,
      this.customer?.email_address ? this.customer : null, 
      this.policyAttributes, 
      this.quoteId,
      metadata
    ).then((quoteResponse: any) => {
      //this.setQuoteId(quoteResponse.quote_id);
      this.quote = quoteResponse;
      this.dispatchQuoteUpdate();

      let updatedProductDetails = quoteResponse.product;

      if (this.suffixContent) {
        updatedProductDetails['suffixContent'] = this.suffixContent;
      }

      this.productDetails = updatedProductDetails;
      this.dispatchProductDisplayUpdate();

      this.loading = false;
      this.hidden = false;
      return new Promise((resolve) => resolve(true));
    }).catch((response) => {
      if (response.statusCode === 400) {
        this.hidden = true;
        this.loading = false;
        response.data?._embedded?.errors.forEach(e => console.error(e.message));
      } else if (response.statusCode === 422 || response.statusCode === 404) {
        //this.unsetQuoteId();
        return this.load();
      } else {
        this.hidden = true;
        this.loading = false;
      }

      //return this.loadProductDetails();
    });
  }

  render() {

  }

  private loadProductDetails(productDisplayId?: string) {
    let productPromise;
    
    if (productDisplayId !== undefined) {
      productPromise = api.getProductDetailsWithDisplayOverride(this?.clientId, this.product, productDisplayId);
    } else {
      productPromise = api.getProductDetails(this?.clientId, this.product);
    }
    
    return productPromise.then((productResponse: any) => {
      this.productDetails = productResponse.product;
      this.dispatchProductDisplayUpdate();
      this.loading = false;
    });
  }

  private registerSelf() {
    if (!this.registered) {
      this.dispatchEvent(new CustomEvent("registerProduct", {detail: this, bubbles: true}));
      this.registered = true;
    }
  }

  private dispatchQuoteUpdate() {
    this.dispatchEvent(new CustomEvent("quoteUpdate", {detail: this, bubbles: true}));
  }

  private dispatchProductDisplayUpdate() {
    this.dispatchEvent(new CustomEvent("productDisplayUpdate", {detail: this, bubbles: true}));
  }

  // private getQuoteId() {
  //   let cookieName = this.parentElement?.dataset?.uniqueId ? `vertical_insure_quote_id:${this.product}:${this.parentElement?.dataset?.uniqueId}` : `vertical_insure_quote_id:${this.product}`
  //   const quoteId: string | undefined = document.cookie
  //   .split('; ')
  //   .find((row) => row.split("=")[0] === cookieName)
  //   ?.split('=')[1];
  //   return quoteId;
  // }

  // private setQuoteId(quoteId: string) {
  //   let cookieName = this.parentElement?.dataset?.uniqueId ? `vertical_insure_quote_id:${this.product}:${this.parentElement?.dataset?.uniqueId}` : `vertical_insure_quote_id:${this.product}`
  //   document.cookie = `${cookieName}=${quoteId};max-age=34560000; Secure`
  // }

  // private unsetQuoteId() {
  //   let cookieName = this.parentElement?.dataset?.uniqueId ? `vertical_insure_quote_id:${this.product}:${this.parentElement?.dataset?.uniqueId}` : `vertical_insure_quote_id:${this.product}`
  //   document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  // }
}

declare global {
  interface HTMLElementTagNameMap {
    'vi-product': ViProduct
  }
}

export { ViProduct }