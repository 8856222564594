import { Customer } from "./model/customer";
import { parseJwt } from "./util";

const apiUrl = 'https://api.verticalinsure.com';

class ResponseError extends Error {
  declare statusCode;
  declare data;
  constructor(statusCode, data) {
    super();
    this.statusCode = statusCode;
    this.data = data;
  }
}

const handleResponse = (response: Response) => {
  if (response.ok) {
    return response.json();
  } else {
    return response.json().then((json) => { throw new ResponseError(response.status, json); })
  }
}

export const api = {
  getProductDetails: (clientId: string, product: string): Promise<any> => {
    let endpoint = `${apiUrl}/v1/products/${product}`
    return fetch(endpoint, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'x-api-client-id': clientId
      }
    }).then((response) => {
      return handleResponse(response);
    });
  },
  getProductDetailsWithDisplayOverride: (clientId: string, product: string, productDisplayId: string): Promise<any> => {
    let endpoint = `${apiUrl}/v1/products/${product}/display/${productDisplayId}`
    return fetch(endpoint, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'x-api-client-id': clientId
      }
    }).then((response) => {
      return handleResponse(response);
    });
  },
  createQuote: (
    clientId: string, 
    product: string, 
    policy_start_date: Date, 
    policy_end_date: Date, 
    customer: Customer | undefined | null, 
    policyAttributes: any, 
    quoteId: string | undefined,
    metadata: any
  ): Promise<any>  => {
    let endpoint = `${apiUrl}/v1/quote/${product}`
    
    let body: {[k: string]: any} = {
      policy_start_date: policy_start_date,
      policy_end_date: policy_end_date,
      customer: customer, 
      policy_attributes: policyAttributes,
      metadata: metadata
    }

    if (quoteId != undefined) {
      body.quote_id = quoteId;
    }

    return fetch(endpoint, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-api-client-id': clientId
      },
      body: JSON.stringify(body)
    }).then((response) => {
      return handleResponse(response);
    });
  },
  getPaymentMethods: (clientId: string, customerClientSecret: string) => {
    let customerId = parseJwt(customerClientSecret).customer_id;
    let endpoint = `${apiUrl}/v1/customers/${customerId}/payment-method`;

    return fetch(endpoint, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'x-api-client-id': clientId,
        'x-api-customer-client-secret': customerClientSecret
      }
    }).then((response) => {
      return handleResponse(response);
    });
  }
}
