import {html} from 'lit';
import { when } from 'lit/directives/when.js';

export const SpinnerOnly = (size?: string) => (html`
  <div class="lds-ring ${size ? size : ''}"><div></div><div></div><div></div><div></div></div>
`)


export const Loader = (includePadding?: string, size?: string) => (html`
  <div class="loader-wrapper">
    <div class="loader-background">
      ${SpinnerOnly(size)}
    </div>
    ${when(includePadding !== undefined, () => html`
      <div style="min-height: ${includePadding}"></div>
    `)}
  </div>
`)