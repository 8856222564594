import {css} from 'lit';

export const loaderCss = css`
  .loader-background {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 60px;
    background-color: rgba(1,1,1,0.05);
    border-radius: var(--vi-border-radius, var(--vi-default-border-radius));
    z-index: 10;
  }

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 60px;
    height: 60px;
  }
  .lds-ring.small {
    width: 30px;
    height: 30px;
  }

  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 44px;
    height: 44px;
    margin: 8px;
    border: 4px solid var(--vi-color-primary, var(--vi-default-color-primary));
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--vi-color-primary, var(--vi-default-color-primary)) transparent transparent transparent;
  }

  .lds-ring.small div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 22px;
    height: 22px;
    margin: 4px;
    border: 2px solid var(--vi-color-primary, var(--vi-default-color-primary));
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--vi-color-primary, var(--vi-default-color-primary)) transparent transparent transparent;
  }

  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`