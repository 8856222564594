import { html } from "lit";
import { when } from 'lit/directives/when.js';
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import { formatDollar } from "../util.js";
import { FunctionalComponent } from "model/functional-component.js";

export const MinimalProduct: FunctionalComponent = ({showProductIcon, selected, onSelect, productDetails, quote }) => html`
  <div class="simple-product-wrapper ${selected ? 'selected' : ''}" @click=${onSelect}>
    <div class="product">
      ${when(showProductIcon, () => html`<div class="icon"><span class="material-icons md-48">${productDetails.promotional_icon}</span></div>`, () => '')}
      <div class="title">${productDetails.friendly_name}</div>
      <div class="description">${unsafeHTML(productDetails.promotional_description)}</div>
      <div class="price">${formatDollar(quote?.premium_amount)}</div>
    </div>
  </div>
`;

export const MinimalProductDecline = (products: Array<any>, selected: boolean, onClick: Function) => html`
  <div class="vi-simple-product-decline radio-button-wrapper">
    <input type="radio" name="decline_coverage" id="decline-coverage" ?checked=${selected}><label @click=${onClick}> I do not want to protect my investment with ${products.map(p => p.productDetails.friendly_name).join(', or ')}</label>
  </div>
`

export const MinimalProductSelectAll = (products: Array<any>, selected: boolean, showProductIcon: boolean, onClick: Function) => html`
  <div class="simple-product-wrapper ${selected ? 'selected' : ''}" @click=${onClick}>
    <div class="product">
      ${when(showProductIcon, () => html`<div class="icon"><span class="material-icons md-48">verified_user</span></div>`, () => '')}
      <div class="title">Complete Protection!</div>
      <div class="description">
        <p>Purchase ${unsafeHTML(products.map(p => `<strong>${p?.productDetails.friendly_name}</strong>`).join(', and '))}</p>
      </div>
      <div class="price">
        ${formatDollar(products.reduce((accumulator, product) => accumulator + product.quote.premium_amount, 0))}
      </div>
    </div>
  </div>
`