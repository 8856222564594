import { html } from "lit";
import {unsafeHTML} from 'lit/directives/unsafe-html.js';
import { when } from "lit/directives/when.js";
import { formatDollar } from "../util.js";
import { FunctionalComponent } from "model/functional-component.js";

export const RadioButtonProduct: FunctionalComponent = ({selected, onSelect, onDecline, productDetails, policyAttributes, quote }) => html`
  ${when(quote !== undefined, () => html`
    <div class="radio-product-wrapper ${selected ? 'selected' : ''}">
      <div class="grid">
        <div class="row">
          <div>
            <div class="header">Protect Your Purchase</div>
            <div>${unsafeHTML(productDetails.promotional_description)}</div>
          </div>
          <div>
            <div class="header">Protect Against</div>
            <div>
              <ul>
                ${productDetails.perils?.map(i => html`<li>${i}</li>`)}
              </ul>
            </div>
          </div>
        </div>
        <div class="row options">
          <div class="vi-radio-product-accept radio-button-wrapper radio-toggle" @click=${onSelect}>
            <input type="radio" required name="coverage_accepted" id="accept-coverage" ?checked=${selected === true}>
            <label for="accept-coverage">
              Yes, protect my purchase for only <strong>${formatDollar(quote?.premium_amount)}</strong>
            </label>
            <div class="check"></div>
          </div>

          <div class="vi-radio-product-decline radio-button-wrapper radio-toggle" @click=${onDecline}>
            <input type="radio" required name="coverage_accepted" id="decline-coverage" ?checked=${selected === false}>
            <label for="decline-coverage">
              No, do not protect my purchase of ${formatDollar(policyAttributes.insurable_amount)}
            </label>
            <div class="check"></div>
          </div>
        </div>
      </div>
    </div>
  `)}
`; 