import { css } from "lit";

export const radioButtonProductCss = css`
  .radio-product-wrapper {
    min-height: 200px;
  }

  .grid {
    box-sizing: border-box; 
    display: block;
    width: 100%;
    border: 1px solid var(--vi-color-border, var(--vi-default-color-border));
    border-radius: var(--vi-border-radius, var(--vi-default-border-radius));
  }

  .grid .row {
    display: flex;
    flex-direction: row;
    flex: 1;
  }

  .grid .row > div > div {
    padding: 0.75em;
  }

  .grid .row p {
    margin: 0;
  }

  .grid .row.options {
    border-top: 1px solid var(--vi-color-border, var(--vi-default-color-border));
  }

  .grid .row.options > div > div {
    padding: 0;
  }

  .grid .row.options div label {
    display: block;
    font-size: 0.95rem;
  }

  .vi-radio-product-accept input:checked + label {
    box-sizing: border-box; 
    background: var(--vi-color-success-bg, var(--vi-default-color-success-bg));
    color: var(--vi-color-success-font, var(--vi-default-color-success-font));
    border-radius: 0;
    border-bottom-left-radius: var(--vi-border-radius, var(--vi-default-border-radius));
  }

  .vi-radio-product-decline input:checked + label {
    box-sizing: border-box; 
    background: var(--vi-color-danger-bg, var(--vi-default-color-danger-bg));
    color: var(--vi-color-danger-font, var(--vi-default-color-danger-font));
    border-radius: 0;
    border-bottom-right-radius: var(--vi-border-radius, var(--vi-default-border-radius));
  }

  .grid .row > div:not(:last-child) {
    box-sizing: border-box; 
    border-right: 1px solid var(--vi-color-border, var(--vi-default-color-border));
  }

  .grid .header {
    box-sizing: border-box; 
    background: var(--vi-color-primary, var(--vi-default-color-primary));
    color: var(--vi-color-primary-contrast-text, var(--vi-default-color-primary-contrast-text));
    border-bottom: 1px solid var(--vi-color-border, var(--vi-default-color-border));
    font-weight: bold;
    white-space: nowrap;
    font-family: var(--vi-default-title-font);
  }

  .grid .row > div:first-child .header {
    box-sizing: border-box; 
    border-top-left-radius: var(--vi-border-radius, var(--vi-default-border-radius));
  }

  .grid .row > div:last-child .header {
    box-sizing: border-box; 
    border-top-right-radius: var(--vi-border-radius, var(--vi-default-border-radius));
  }

  .grid .row div {
    flex: 1;
    box-sizing: border-box; 
  }

  .grid .row div ul {
    margin: 0;
    list-style: none;
    list-style-position: outside;
    column-width: min-content;
    padding-inline-start: 0;
    display: flex;
    flex-wrap: wrap;
  }

  .grid .row ul li {
    padding-left: 0;
    display: flex;
    flex-direction: row;
    padding: 0.2rem;
    flex: 1 1 200px;
  }
  .grid .row div ul li:before {
    display: inline-block;
    min-width: 25px;
    content: "\\2718";
    color: var(--vi-color-danger, var(--vi-default-color-danger));
  }

  .selected .grid .row div ul li:before {
    display: inline-block;
    min-width: 25px;
    content: "\\2714\\0020";
    color: var(--vi-color-success, var(--vi-default-color-success));
  }

  .radio-toggle input[type=radio] {
    position: absolute;
    visibility: hidden;
  }
  
  .vi-radio-product-accept input[type=radio]:checked ~ .check {
    border: 3px solid var(--vi-color-success, var(--vi-default-color-success));;
  }

  .vi-radio-product-accept input[type=radio]:checked ~ .check::before{
    background: var(--vi-color-success, var(--vi-default-color-success));
  }

  .vi-radio-product-decline input[type=radio]:checked ~ .check {
    border: 3px solid var(--vi-color-danger, var(--vi-default-color-danger));
  }

  .vi-radio-product-decline input[type=radio]:checked ~ .check::before{
    background: var(--vi-color-danger, var(--vi-default-color-danger));
  }

  .radio-toggle label {
    padding: 0.75rem 0.75rem 0.75rem 2.75em;
  }

  .radio-toggle .check {
    display: block;
    position: absolute;
    border: 3px solid #AAAAAA;
    border-radius: 100%;
    height: 18px;
    width: 18px;
    top: 0.8em;
    left: 1em;
    z-index: 5;
    pointer-events: none;
  }

  .radio-toggle .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 8px;
    width: 8px;
    top: 2px;
    left: 2px;
    margin: auto;
    pointer-events: none;
  }

  @media only screen and (max-width: 768px) {
    .grid .row {
      flex-direction: column;
    }

    .grid .row > div:not(:last-child) {
      border-right: none;
      border-bottom: 1px solid var(--vi-color-border, var(--vi-default-color-border));
    }

    .grid .row ul {
      column-gap: 0px;
      -webkit-column-gap: 10px;
    }

    .grid .row > div:first-child .header {
      border-top-left-radius: var(--vi-border-radius, var(--vi-default-border-radius));
      border-top-right-radius: var(--vi-border-radius, var(--vi-default-border-radius));
    }

    .grid .row > div:last-child .header {
      border-radius: 0;
    }
  }
`